import { Assistant } from './models';

interface AssistantPreviewProps {
    assistant: Assistant | null;
    thread_id?: string | null;
}

const AssistantPreview: React.FC<AssistantPreviewProps> = ({ assistant, thread_id }) => {

    if (!assistant) {
        return null;
    }

    return (
        <iframe
            key={JSON.stringify(assistant)}
            src={`https://components.reliablegenius.io/assistant/${assistant.id}${thread_id ? `?thread_id=${thread_id}` : ''}`}
            title="Assistant Preview"
            width="100%"
            height="100%"
        ></iframe>
    )
}

export default AssistantPreview;