import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline, PaletteMode } from "@mui/material";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

export interface ThemeProps {
}

const ThemeComponent: React.FC<ThemeProps> = () => {

    const [themeMode, setThemeMode] = useState<PaletteMode>('dark');

    const toggleTheme = () => {
      setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
    };
  
    const theme = createTheme({
      palette: {
        mode: themeMode,
        primary: {
          main: '#3765CE',
        },
        secondary: {
          main: '#d6336c',
        },
        warning: {
          main: '#f44336',
        },
      },
      typography: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 16,
        htmlFontSize: 16,
        button: {
          textTransform: 'capitalize'
        }
      }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter >
                <App theme={theme} toggleTheme={toggleTheme}/>
            </BrowserRouter>
        </ThemeProvider>
    );
}


export default ThemeComponent;