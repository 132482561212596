import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import AssistantSlackConversations from "./AssistantSlackConversations";
import AssistantSlackOrganization from "./AssistantSlackOrganization";
import AssistantSlackPersona from "./AssistantSlackPersona";
import AssistantSlackUsers from "./AssistantSlackUsers";
import { SERVER_URL } from "./environment";
import { Assistant } from "./models";

interface AssistantSlackProps {
    assistant: Assistant;
    updateAssistant: (assistant: Assistant) => Promise<void>;
}

const AssistantSlack: React.FC<AssistantSlackProps> = ({ assistant, updateAssistant }) => {

    const [isInstalled, setIsInstalled] = useState<boolean>(false);
    const [needUpdate, setNeedUpdate] = useState<boolean>(false);
    const [needAuthorization, setNeedAuthorization] = useState<boolean>(false);
    const [oauthUrl, setOauthUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleDoesSlackNeedUpdate = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/need-update`);
            const data = response.data;
            if (data.needUpdate) {
                setNeedUpdate(true);
            }
        } catch (err) {
            setError('Failed to check if Slack app needs update.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id]);

    const handleDoesSlackNeedAuthorization = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/authorize`);
            const data = response.data;
            if (data.needAuthorization) {
                setNeedAuthorization(true);
                setOauthUrl(data.oauthUrl);
            }
        } catch (err) {
            setError('Failed to check if Slack app needs authorization.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id]);

    const isSlackAppInstalled = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/installed`);
            const data = response.data;
            if (data.installed) {
                setIsInstalled(true);
                handleDoesSlackNeedUpdate();
                handleDoesSlackNeedAuthorization();
            }
        } catch (err) {
            setError('Failed to check if Slack app is installed.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, handleDoesSlackNeedUpdate, handleDoesSlackNeedAuthorization]);

    const handleAddToSlack = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack`);
            const data = response.data;
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (err) {
            setError('Failed to add to Slack.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id]);

    const handleSlackUpdate = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/update`);
            setTimeout(async () => await handleDoesSlackNeedUpdate(), 2000);
        } catch (err) {
            setError('Failed to update Slack.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, handleDoesSlackNeedUpdate]);

    const handleSlackReinstall = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            if (!oauthUrl) {
                throw new Error('Failed to re-install Slack.');
            }
            window.location.href = oauthUrl;
        } catch (err) {
            setError('Failed to re-install Slack.');
        } finally {
            setLoading(false);
        }
    }, [oauthUrl]);

    const handleSlackDelete = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${SERVER_URL}/admin/assistants/${assistant.id}/slack`);
            setTimeout(async () => await isSlackAppInstalled(), 2000);
        } catch (err) {
            setError('Failed to remove from Slack.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, isSlackAppInstalled]);

    useEffect(() => {
        isSlackAppInstalled();
    }, [isSlackAppInstalled]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <h1>Slack Settings</h1>
            <Box display="flex" flexDirection="row" justifyContent="center">
                {loading && <Box className="flex items-center justify-center min-h-screen">
                    <CircularProgress size={100} />
                </Box>}
                {error && <Typography color="error">{error}</Typography>}
                {!loading && !error && !isInstalled && <Box display="flex" justifyContent="center" className="m-4">
                    <button onClick={handleAddToSlack}
                        style={{
                            alignItems: 'center',
                            color: '#000',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '18px',
                            fontWeight: 600,
                            height: '56px',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: '276px'
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            style={{ height: '24px', width: '24px', marginRight: '12px' }}
                            viewBox="0 0 122.8 122.8">
                            <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                            <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                            <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                            <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                        </svg>
                        Create and Install Slack App
                    </button>
                </Box>}
                {!loading && !error && needUpdate && <Box display="flex" justifyContent="center" className="m-4">
                    <button onClick={handleSlackUpdate}
                        style={{
                            alignItems: 'center',
                            color: '#000',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '18px',
                            fontWeight: 600,
                            height: '56px',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: '276px'
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            style={{ height: '24px', width: '24px', marginRight: '12px' }}
                            viewBox="0 0 122.8 122.8">
                            <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                            <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                            <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                            <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                        </svg>
                        Update Slack App
                    </button>
                </Box>}
                {!loading && !error && needAuthorization && <Box display="flex" justifyContent="center" className="m-4">
                    <button onClick={handleSlackReinstall}
                        style={{
                            alignItems: 'center',
                            color: '#000',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '18px',
                            fontWeight: 600,
                            height: '56px',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: '276px'
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            style={{ height: '24px', width: '24px', marginRight: '12px' }}
                            viewBox="0 0 122.8 122.8">
                            <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                            <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                            <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                            <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                        </svg>
                        Re-install Slack App
                    </button>
                </Box>}
                {!loading && !error && isInstalled && <Box display="flex" justifyContent="center" className="m-4">
                    <button onClick={handleSlackDelete}
                        style={{
                            alignItems: 'center',
                            color: '#000',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '18px',
                            fontWeight: 600,
                            height: '56px',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            width: '276px'
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            style={{ height: '24px', width: '24px', marginRight: '12px' }}
                            viewBox="0 0 122.8 122.8">
                            <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                            <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                            <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                            <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                        </svg>
                        Delete Slack App
                    </button>
                </Box>}
            </Box>
            {isInstalled && <AssistantSlackPersona assistant={assistant} />}
            {isInstalled && <AssistantSlackOrganization assistant={assistant} />}
            {isInstalled && <AssistantSlackConversations assistant={assistant} />}
            {isInstalled && <AssistantSlackUsers assistant={assistant} />}
        </Box>
    );
};

export default AssistantSlack;