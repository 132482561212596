import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Divider, FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField, TextareaAutosize, ToggleButton, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Action } from './AssistantConfigureAction';
import { OBJECT_STORAGE_URL, SERVER_URL } from './environment';
import { Assistant, OpenAIFile, Tool } from './models';

interface AddAssistantModalProps {
  initialData: Assistant;
  updateAssistant: (assistant: Assistant) => Promise<void>;
  setSubView: (subView: string) => void;
  setAction: (action: Action | null) => void;
  setOpenSnackbar: (message: string) => void;
}

export const HEADER_HEIGHT = 200;

const AssistantConfigure: React.FC<AddAssistantModalProps> = ({ initialData, updateAssistant, setSubView, setAction, setOpenSnackbar }) => {

  const { register, setValue, control, watch, getValues } = useForm<Assistant>({
    defaultValues: {
      ...initialData,
    },
  });
  const iconInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<OpenAIFile[]>([]);
  const fileIds = watch('file_ids');

  useEffect(() => {
    const fetchFiles = async () => {
      if (fileIds?.length > 0) {
        const filePromises = fileIds.map(id =>
          getFile(id).catch(error => {
            if (error.response?.status === 404) {
              return null;
            }
            throw error;
          })
        );
        const localFiles = (await Promise.all(filePromises)).filter(file => file !== null) as OpenAIFile[];
        setFiles(localFiles);
        const localFileIds = localFiles.map(file => file.id);
        if (JSON.stringify(localFileIds) !== JSON.stringify(fileIds)) {
          setValue('file_ids', localFileIds);
        }
      }
    };

    fetchFiles();
  }, [fileIds, setValue]);

  const handleAddFile = async (e: { target: { files: FileList | null; }; }) => {
    if (!e.target.files) {
      return;
    }
    const newFiles = Array.from(e.target.files) as File[];
    const fileDataPromises = newFiles.map(file => createFile(file).catch(e => {
      console.error(e);
      setOpenSnackbar(e.response?.data?.detail || e.message || "Failed to upload file. Please try again.");
      return null
    }));
    const fileData = (await Promise.all(fileDataPromises)).filter(file => file !== null) as OpenAIFile[];
    setFiles([...files, ...fileData]);
    setValue('file_ids', [...fileIds, ...fileData.map(file => file.id)]);
  };

  const handleDeleteFile = async (fileId: string) => {
    await deleteFile(fileId);
    setFiles(files.filter(file => file.id !== fileId));
    setValue('file_ids', fileIds.filter(id => id !== fileId));
  };

  const handleAddFileClick = () => {
    fileInputRef.current?.click();
  };

  const createImage = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.post(`${SERVER_URL}/admin/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }

  const getFile = async (file_id: string): Promise<OpenAIFile> => {
    return await axios.get(`${SERVER_URL}/admin/files/${file_id}`).then(response => response.data);
  }

  const createFile = async (file: File): Promise<OpenAIFile> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.post(`${SERVER_URL}/admin/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }

  const deleteFile = async (file_id: string) => {
    await axios.delete(`${SERVER_URL}/admin/files/${file_id}`);
  }

  const initialRender = useRef(true);

  // Autosaving algorithm
  const debounceMs = 1000;
  const values = watch();

  const debouncedSave = useRef(debounce(async (values) => {
    await updateAssistant(values);
  }, debounceMs)).current;

  const saveData = useCallback(() => {
    const currentValues = getValues(); // Get the current values
    debouncedSave(currentValues);
  }, [getValues, debouncedSave]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'starters'
  } as any);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (JSON.stringify(values) !== JSON.stringify(initialData))
      saveData();
  }, [values, saveData, initialData]);

  return (
    <form>
      <Paper style={{ maxHeight: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="px-4 scrollbar">
        <div className="flex justify-center w-full">
          <Controller
            name="icon"
            control={control}
            render={({ field }) => (
              <>
                <input
                  accept="image/*"
                  id="icon-upload"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      createImage(file).then((data) => {
                        field.onChange(`${OBJECT_STORAGE_URL}/images/${data}`)
                      });
                    }
                  }}
                  ref={iconInputRef}
                />
                <label htmlFor="icon-upload">
                  <IconButton
                    color="primary"
                    component="span"
                    className="mx-auto my-6">
                    {
                      field?.value ? (
                        <img src={field.value} alt="Icon preview" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                      ) : <AddIcon style={{ fontSize: 80 }} />
                    }
                  </IconButton>
                </label>
              </>
            )}
          />
        </div>
        <TextField
          label="Name"
          {...register('name', { required: true })}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{ maxLength: 35 }}
        />
        <TextField
          label="Description"
          {...register('description', { required: true })}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          inputProps={{ maxLength: 140 }}
        />
        <Controller
          name="instructions"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth variant="outlined" error={fieldState.invalid}>
              <InputLabel htmlFor="instructions">Instructions</InputLabel>
              <OutlinedInput
                id="instructions"
                label="Instructions"
                multiline
                rows={4} // minimum number of rows
                maxRows={10} // maximum number of rows
                inputComponent={TextareaAutosize}
                style={{ overflow: 'auto', resize: 'both' }}
                {...field}
              />
              {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Typography variant="h6" component="h2" className="text-white">
          Conversation Starters
        </Typography>
        {fields.map((field, index) => (
          <Box key={field.id} display="flex" justifyContent="space-between" className="my-2">
            <Controller
              name={`starters.${index}` as const}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  name="starters"
                  onChange={e => {
                    setValue(`starters.${index}`, e.target.value);
                  }}
                  fullWidth
                />
              )}
            />
            <IconButton
              type="button"
              onClick={() => remove(index)}
              className="ml-4 text-red-500"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Box className="my-2 flex justify-end w-full">
          <IconButton
            type="button"
            onClick={() => append('')}
            className="bg-blue-500 text-white"
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Box>
          <InputLabel id="model-label" style={{ color: '#fff' }}>Model</InputLabel>
          <Select
            labelId="model-label"
            label="Model"
            id="model"
            variant="outlined"
            defaultValue="gpt-4o"
            margin="dense"
            fullWidth
            {...register('model')}
          >
            <MenuItem value={'gpt-4o'}>gpt-4o</MenuItem>
            <MenuItem value={'gpt-4o-mini'}>gpt-4o-mini</MenuItem>
            <MenuItem value={'gpt-4-turbo'}>gpt-4-turbo</MenuItem>
            <MenuItem value={'gpt-3.5-turbo'}>gpt-3.5-turbo</MenuItem>
          </Select>
        </Box>
        <Divider className="my-4" />
        <Box mt={2}>
          <Typography variant="h6" component="h2" className="text-white">
            Features
          </Typography>
        </Box>
        <Controller
          control={control}
          name="tools"
          defaultValue={[] as Tool[]}
          render={({ field }) => (
            <>
              <ToggleButtonGroup
                color="primary"
                value={Array.isArray(field.value) ? field.value.map((tool) => tool.type) : []}
                onChange={(event, newValues) => field.onChange(newValues.map((type: string) => ({ type })))}
              >
                {/* <ToggleButton value="function">Functions</ToggleButton> */}
                <ToggleButton value="code_interpreter">Code Interpreter</ToggleButton>
                <ToggleButton value="retrieval">Retrieval</ToggleButton>
              </ToggleButtonGroup>
              <Box mb={2} />
              {/* {field.value.map((tool, index) => tool.type === 'function' && (
                <>
                  <TextField
                    {...register(`tools.${index}.function.name`)}
                    id={`function-name-${index}`}
                    label="Function Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextField
                    {...register(`tools.${index}.function.description`)}
                    id={`function-description-${index}`}
                    label="Function Description"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    fullWidth
                    required
                  />
                  <Controller
                    name={`tools.${index}.function.parameters`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          onChange={e => {
                            setInputValue(e.target.value);
                            try {
                              const parsedValue = JSON.parse(e.target.value);
                              onChange(parsedValue);
                              setJsonError(false);
                            } catch (error) {
                              setJsonError(true);
                            }
                          }}
                          value={jsonError ? inputValue : JSON.stringify(value)}
                          id={`function-parameters-${index}`}
                          label="Function Parameters"
                          variant="outlined"
                          margin="normal"
                          multiline
                          rows={4}
                          fullWidth
                          error={jsonError}
                          helperText={jsonError ? "Invalid JSON" : ""}
                        />
                      </>
                    )}
                  />
                </>
              ))} */}

              {(field.value.find(tool => tool.type === 'code_interpreter') || field.value.find(tool => tool.type === 'retrieval')) && (
                <>
                  <Box className="flex flex-row justify-between my-2">
                    <Typography id="modal-modal-title" variant="h6" component="h2" className="text-white">
                      Files
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept=".c, .cpp, .csv, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .tar, .ts, .xlsx, .xml, .zip"
                      id="file-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddFile}
                      multiple
                    />
                  </Box>
                  <List>
                    {files.map((file) => (
                      <ListItem key={file.id}>
                        <ListItemText primary={file.filename} style={{ color: '#fff' }} />
                        <IconButton edge="end" aria-label="delete" color="warning" onClick={() => handleDeleteFile(file.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                    <Box className="my-2 px-1 flex justify-end w-full">
                      <IconButton
                        type="button"
                        onClick={handleAddFileClick}
                        className="bg-blue-500 text-white"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </List>

                </>
              )}
              <Box className="mb-16">
                <Divider className="my-2" />
                <Typography variant="h6" component="h2" className="text-white mb-4">
                  Actions
                </Typography>
                <List>

                </List>
                <Box className="my-2 px-1 flex justify-end w-full">
                  <IconButton
                    type="button"
                    onClick={() => {
                      setSubView('action');
                      setAction(null);
                    }}
                    className="bg-blue-500 text-white"
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            </>
          )} />
      </Paper>
    </form>
  );
};

export default AssistantConfigure;