import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SERVER_URL } from "./environment";

const SlackOauth: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const hasFetched = useRef(false); // Ref to track if the request has been made
    const [error, setError] = useState<string | null>(null); // State to store error message

    useEffect(() => {
        const fetchOauth = async () => {
            if (hasFetched.current) return; // If request has already been made, return
            hasFetched.current = true; // Mark the request as made

            console.log('code:', searchParams.get('code'));
            console.log('state:', searchParams.get('state'));

            try {
                const response = await axios.get(`${SERVER_URL}/admin/slack/oauth?code=${searchParams.get('code')}&state=${searchParams.get('state')}`);
                const data = response.data;
                navigate(`/assistants/${data.id}`);
            } catch (error) {
                console.error('Error fetching OAuth:', error);
                setError('Failed to complete OAuth process. Please try again.'); // Set error message
            }
        };

        fetchOauth();
    }, [searchParams, navigate]);

    return (
        <Box className="flex items-center justify-center min-h-screen">
            {error ? (
                <Typography color="error">{error}</Typography> // Display error message
            ) : (
                <CircularProgress size={100} />
            )}
        </Box>
    );
};

export default SlackOauth;