import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Role, User } from './Teams';
import { SERVER_URL } from './environment';

interface UserModalProps {
    mode: 'add' | 'edit';
    user?: User;
    handleClose: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ mode, user, handleClose }) => {
    const { register, handleSubmit, control, watch } = useForm<User>({
        defaultValues: user || {
            role: Role.Owner,
            isBillingNotified: true
        },
    });
    const role = watch('role');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSubmit = async (user: User) => {
        if (mode === 'add') {
            await addUser(user);
        } else {
            await updateUser(user);
        }
    }

    const addUser = async (user: User) => {
        if (user.role === Role.Developer) {
            user.isBillingNotified = false;
        }
        setLoading(true);
        return axios.post(`${SERVER_URL}/admin/teams`, user)
            .then(response => {
                setLoading(false);
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                setError(error.message);
            });
    };

    const updateUser = async (user: User) => {
        if (user.role === Role.Developer) {
            user.isBillingNotified = false;
        }
        setLoading(true);
        return axios.put(`${SERVER_URL}/admin/teams/${user.id}`, user)
            .then(response => {
                setLoading(false);
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                setError(error.message);
            });
    };

    const handleSubmitClick = () => {
        handleSubmit(onSubmit)();
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1">{error}</Typography>;
    }
    return (
        <Formik initialValues={user || { role: Role.Owner, isBillingNotified: true }} onSubmit={onSubmit}>
            <Form>
                <Dialog open={true} onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <DialogTitle className="capitalize">{mode} User</DialogTitle>
                    <DialogContent>
                        <Box
                            className="my-4"
                        >
                            <TextField
                                label="Email"
                                {...register('email', { required: true })}
                                required
                                fullWidth
                            />
                        </Box>
                        <Box
                            className="my-4">
                            <Controller
                                name="role"
                                control={control}
                                defaultValue={Role.Owner}
                                render={({ field }) => (
                                    <Select
                                        label="Role"
                                        {...field}
                                        fullWidth
                                    >
                                        <MenuItem value={Role.Owner}>Owner</MenuItem>
                                        <MenuItem value={Role.Billing}>Billing</MenuItem>
                                        <MenuItem value={Role.Developer}>Developer</MenuItem>
                                    </Select>
                                )} />
                        </Box>
                        <Box className="my-4">
                            {role !== Role.Developer && (
                                <Controller
                                    name="isBillingNotified"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} defaultChecked={field.value} />}
                                            label="Receive email notifications for billing events"
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmitClick}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </Form>
        </Formik>
    );
};

export default UserModal;
