import ThreePIcon from '@mui/icons-material/ThreeP';
import { Box, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { HEADER_HEIGHT } from "./AssistantConfigure";
import { ADMIN_SERVER_URL } from "./environment";
import { Assistant, Thread } from "./models";

interface AssistantChatsProps {
    assistant: Assistant;
    setThreadId: (thread_id: string) => void;
}

export const AssistantChats: React.FC<AssistantChatsProps> = ({ assistant, setThreadId }) => {

    const assistant_id = assistant.id;

    const [threads, setThreads] = useState<Thread[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchThreads = useCallback(async () => {
        setLoading(true);
        setError(null);
        await axios.get(`${ADMIN_SERVER_URL}/assistants/${assistant_id}/threads`)
            .then((response) => setThreads(response.data))
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, [assistant_id]);

    useEffect(() => {
        fetchThreads();
    }, [assistant_id, fetchThreads]);

    return (
        <Box className="flex flex-col">
            <Paper style={{ maxHeight: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="p-4">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2} className="items-center">
                    <Box display="flex" flexDirection="row" justifyContent="center" className="items-center">
                        {assistant.icon && <img src={assistant.icon} alt="Chatbot icon" className="rounded-full" style={{ width: '80px', height: '80px' }} />}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="items-center mt-2">
                        <Typography variant="h4" align="center">{assistant.name}</Typography>
                        <Typography variant="body1" align="center">{assistant.description}</Typography>
                    </Box>
                </Box>
                <List className="flex flex-col my-2">
                    <Typography variant="subtitle1">Threads</Typography>
                    {loading && <Box className="flex justify-center w-100"><CircularProgress /></Box>}
                    {error && <Typography color="error">{error}</Typography>}
                    {!loading && !error && threads.map((thread) => (
                        <ListItemButton key={thread.id} onClick={() => setThreadId(thread.id)}>
                            <ListItemIcon>
                                {assistant.icon && <img src={assistant.icon} alt="Chatbot icon" className="rounded-full mr-4" style={{ width: '50px', height: '50px' }} />}
                                {!assistant.icon && <ThreePIcon style={{ fontSize: '50px' }} />}
                            </ListItemIcon>
                            <ListItemText primary={thread.id} secondary={thread.createdAt} />
                        </ListItemButton>
                    ))}
                </List>
            </Paper>
        </Box>
    );
};

export default AssistantChats;