import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Assistants from "./Assistants";
import { ADMIN_SERVER_URL } from "./environment";

interface AssistantsGuardProps {

};

const AssistantsGuard: React.FC<AssistantsGuardProps> = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasBillingAccess, setHasBillingAccess] = useState(true);
    const fetchBillingStatus = async () => {
        setLoading(true);
        return await axios.get(`${ADMIN_SERVER_URL}/billing/status`)
            .then(response => response.data.hasBillingAccess)
            .then(setHasBillingAccess)
            .catch(error => {
                setError(error.message);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchBillingStatus();
    }, []);

    if (loading) {
        return (
            <Box className="flex items-center justify-center min-h-screen">
                <CircularProgress size={100}/>
            </Box>
        );
    }
    
    if (error) {
        return (
            <Typography variant="body1" className="flex items-center justify-center min-h-screen text-warning">{error}</Typography>
        );
    }

    return (
        <>
            {hasBillingAccess ? <Assistants /> : <Box className="flex items-center justify-center min-h-screen">There is an issue with Billing please visit the Billing page.</Box>}
        </>
    );
};

export default AssistantsGuard;