import { BusinessOutlined, ExpandMoreOutlined, ManageAccountsOutlined, PersonOutlined, SmartToyOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, Checkbox, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import AssistantSlackMemories from "./AssistantSlackMemories";
import { SERVER_URL } from "./environment";
import { Assistant } from "./models";

interface AssistantSlackUsersProps {
    assistant: Assistant;
}

const AssistantSlackUsers = ({ assistant }: AssistantSlackUsersProps) => {

    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [isDisabledValues, setIsDisabledValues] = useState({} as Record<string, boolean>);

    const setUserIsDisabledCallback = useCallback((userId: string, disabled: boolean) => {
        setIsDisabledValues((prevValues: Record<string, boolean>) => ({
            ...prevValues,
            [userId]: disabled
        }));
    }, [setIsDisabledValues]);

    const getUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users`);
            const data = response.data;
            console.log(data);
            if (data?.members)
                setUsers(data.members);
        } catch (err) {
            setError('Failed to get users list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id]);

    const disableUser = useCallback(async (userId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users/${userId}/disable`, { disabled: true });
            const data = response.data;
            console.log(data);
            setUserIsDisabledCallback(userId, true);
        } catch (err) {
            setError('Failed to disable user.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, setUserIsDisabledCallback]);

    const enableUser = useCallback(async (userId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users/${userId}/enable`, { disabled: false });
            const data = response.data;
            console.log(data);
            setUserIsDisabledCallback(userId, false);
        } catch (err) {
            setError('Failed to enable user.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, setUserIsDisabledCallback]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    return (
        <Card variant="outlined">
            <CardHeader title="Users" />
            <CardContent>
                {loading && <Box className="flex items-center justify-center min-h-screen">
                    <CircularProgress size={100} />
                </Box>}
                {error && <Typography color="error">{error}</Typography>}
                {users?.map((user, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}>
                            <Checkbox
                                checked={Boolean(!isDisabledValues[user.id])}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (!isDisabledValues[user.id]) {
                                        disableUser(user.id);
                                    } else {
                                        enableUser(user.id);
                                    }
                                }} />
                            <Box className="mx-2 my-2">
                                <img src={user.profile.image_32} alt={user.real_name || user.name} className="rounded-lg" />
                            </Box>
                            <Box className="my-2">
                                {user.is_bot ? <SmartToyOutlined /> :
                                    user.is_owner ? <BusinessOutlined /> :
                                        user.is_admin ? <ManageAccountsOutlined /> :
                                            <PersonOutlined />}
                            </Box>
                            <Typography className="my-3 mx-2">
                                {user.real_name || user.name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="flex flex-row">
                                <Box className="mx-1">@{user.name}</Box>
                                <Box className="mx-1">{'<'}@{user.id}{'>'}</Box>
                                <Box className="mx-1">{user.real_name}</Box>
                                <Box className="mx-1">{user.is_bot ? '(Bot)' : '(User)'}</Box>
                                <Box className="mx-1">{user.is_owner ? '(Owner)' : ''}</Box>
                                <Box className="mx-1">{user.is_admin ? '(Admin)' : ''}</Box>
                            </Box>
                            <Box className="mt-4">
                                <AssistantSlackMemories assistant={assistant} userId={user.id} setUserIsDisabledCallback={setUserIsDisabledCallback} />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </CardContent>
        </Card>
    );
};

export default AssistantSlackUsers;