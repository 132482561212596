import { Box } from "@mui/material";
import React from "react";
import icon from '../images/icon.svg';

interface BrandIconProps {
  scale?: number;
}

const BrandIcon: React.FC<BrandIconProps> = ({ scale = 1 }) => {
  return (
    <Box 
      className={`flex justify-center items-center transform text-white`} 
      style={{ transform: `scale(${scale})` }}>
      <Box
        component="img"
        sx={{
            height: 35,
            marginTop: 0.7
        }}
        alt="Message Bot Icon"
        src={icon}
        />
      <Box 
        component="h1" 
        className="text-3xl px-1" 
        style={{ 
          fontFamily: 'Poppins', 
          fontWeight: 400, 
          fontKerning: "normal",
          letterSpacing: "-0.32px"
        }}>
          Reliable<span style={{letterSpacing: "-4px"}}> </span>Genius
      </Box>
    </Box>
  );
};

export default BrandIcon;