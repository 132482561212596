import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, OutlinedInput, Paper, Snackbar, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import axios from 'axios';
import React, { useState } from 'react';
import SwaggerClient from 'swagger-client';
import { HEADER_HEIGHT } from './AssistantConfigure';
import { ADMIN_SERVER_URL } from './environment';

interface AssistantConfigureActionProps {
    action: Action | null;
    updateAssistant: (assistant: any) => Promise<void>;
    setSubView: (subView: string) => void;
}

export interface Action {
    id?: string;
    authentication?: {
        type: 'apiKey' | 'oauth2' | 'none';
        apiKey?: string;
        authType?: 'basic' | 'bearer' | 'custom';
        customAuthHeader?: string;
        clientId?: string;
        clientSecret?: string;
        authorizationUrl?: string;
        tokenUrl?: string;
        scope?: string;
        tokenExchangeMethod?: 'POST' | 'BASIC_AUTH_HEADER';
    }
    specUrl?: string;
    specString?: string;
    privacyUrl?: string;
}

const schemaErrors = async (schema: string): Promise<Array<String>> => {
    return new SwaggerClient({ spec: schema }).then((client: any) => {
        console.log(client);
        return client.errors;
    }).catch((error: unknown) => {
        console.error(error);
        return ['Invalid Schema'];
    });
}

const AssistantConfigureAction: React.FC<AssistantConfigureActionProps> = ({ action, updateAssistant, setSubView }) => {

    const [newAction, setNewAction] = React.useState<Action | null>(action);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleOpenDialog = () => setDialogOpen(true);
    const handleCloseDialog = () => setDialogOpen(false);
    const [importSchema, setImportSchema] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState("");

    const onSpecChange = async (spec: string) => {
        // TODO: debounce
        setSpecError(null);

        await schemaErrors(spec).then((errors) => {
            if (errors.length === 0) {
                setNewAction((value) => ({
                    ...value,
                    specString: spec
                }));
            } else {
                setSpecError(`Invalid schema: ${errors.reduce((acc, curr) => acc ? `${acc}, ${curr}` : curr, "")}`);
            }
        });
    }

    const [specError, setSpecError] = React.useState<string | null>(null);

    return (
        <Paper style={{ maxHeight: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="px-4 scrollbar">
            <Box className="flex flex-row p-4">
                <IconButton type="button" color="info" size="large" onClick={() => setSubView('configure')}><ArrowBackIcon /></IconButton>
                <Typography variant="h6" className={`m-auto ${action == null && 'pr-12'}`}>
                    {action === null ? 'Add' : 'Edit'} Action
                </Typography>
                {action !== null && <IconButton type="button" color="warning" size="large"><DeleteIcon /></IconButton>}
            </Box>
            <Box className="m-4">
                <Typography variant="h6" className="my-2">Authentication</Typography>
                <Button variant="outlined"
                    color="primary"
                    className="w-full justify-between"
                    onClick={handleOpenDialog}>
                    {newAction?.authentication?.type || 'None'}
                    <SettingsIcon />
                </Button>
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Authentication</DialogTitle>
                    <DialogContent>
                        <form>
                            <Box className="pt-4 pl-4">
                                <Typography>Authentication Type</Typography>
                            </Box>
                            <ToggleButtonGroup
                                exclusive
                                className="p-4"
                                value={newAction?.authentication?.type || 'none'}
                                onChange={(event, newValues) => setNewAction((value) => ({
                                    ...value,
                                    authentication: {
                                        ...value?.authentication,
                                        type: newValues as 'apiKey' | 'oauth2' | 'none'
                                    }
                                }))}>
                                <ToggleButton color="primary" className="w-full my-2" value="none">None</ToggleButton>
                                <ToggleButton color="primary" className="w-full my-2 text-nowrap" value="apiKey">API Key</ToggleButton>
                                <ToggleButton color="primary" className="w-full my-2" value="oauth2">OAuth2</ToggleButton>
                            </ToggleButtonGroup>
                            {newAction?.authentication?.type === 'apiKey' && <Box className="pt-4 pl-4">
                                <TextField
                                    variant="outlined"
                                    label="Api Key"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.apiKey || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'apiKey',
                                            apiKey: event.target.value
                                        }
                                    }))} />
                                <ToggleButtonGroup
                                    exclusive
                                    value={newAction?.authentication?.authType || 'basic'}
                                    onChange={(event, newValues) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'apiKey',
                                            authType: newValues as 'basic' | 'bearer' | 'custom'
                                        }
                                    }))}>
                                    <ToggleButton color="primary" className="w-full my-2" value="basic">Basic</ToggleButton>
                                    <ToggleButton color="primary" className="w-full my-2" value="bearer">Bearer</ToggleButton>
                                    <ToggleButton color="primary" className="w-full my-2" value="custom">Custom</ToggleButton>
                                </ToggleButtonGroup>
                                {newAction?.authentication?.authType === 'custom' && <TextField
                                    variant="outlined"
                                    label="Custom Auth Header"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.customAuthHeader || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'apiKey',
                                            authType: 'custom',
                                            customAuthHeader: event.target.value
                                        }
                                    }))} />}
                            </Box>}
                            {newAction?.authentication?.type === 'oauth2' && <Box className="pt-4 pl-4">
                                <TextField
                                    variant="outlined"
                                    label="Client ID"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.clientId || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            clientId: event.target.value
                                        }
                                    }))} />
                                <TextField
                                    variant="outlined"
                                    label="Client Secret"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.clientSecret || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            clientSecret: event.target.value
                                        }
                                    }))} />
                                <TextField
                                    variant="outlined"
                                    label="Authorization URL"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.authorizationUrl || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            authorizationUrl: event.target.value
                                        }
                                    }))} />
                                <TextField
                                    variant="outlined"
                                    label="Token URL"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.tokenUrl || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            tokenUrl: event.target.value
                                        }
                                    }))} />
                                <TextField
                                    variant="outlined"
                                    label="Scope"
                                    fullWidth
                                    className="my-2"
                                    value={newAction?.authentication?.scope || ''}
                                    onChange={(event) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            scope: event.target.value
                                        }
                                    }))} />
                                <ToggleButtonGroup
                                    exclusive
                                    value={newAction?.authentication?.tokenExchangeMethod || 'POST'}
                                    onChange={(event, newValues) => setNewAction((value) => ({
                                        ...value,
                                        authentication: {
                                            ...value?.authentication,
                                            type: 'oauth2',
                                            tokenExchangeMethod: newValues as 'POST' | 'BASIC_AUTH_HEADER'
                                        }
                                    }))}>
                                    <ToggleButton color="primary" className="w-full my-2" value="POST">POST</ToggleButton>
                                    <ToggleButton color="primary" className="w-full my-2 text-nowrap" value="BASIC_AUTH_HEADER">Basic Auth Header</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCloseDialog} color="inherit">Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box className="m-4">
                <Typography variant="h6" className="my-2">Schema</Typography>
                <Box className="flex justify-end my-4">
                    {importSchema && <TextField
                        variant="outlined"
                        label="Schema URL"
                        fullWidth
                        value={newAction?.specUrl || ''}
                        onChange={(event) => setNewAction((value) => ({
                            ...value,
                            specUrl: event.target.value
                        }))} />}
                    <Button variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (!importSchema) {
                                setImportSchema(!importSchema)
                            } else {
                                // Fetch Schema URL and set specString
                                if (!newAction?.specUrl) return;

                                axios.get(`${ADMIN_SERVER_URL}/proxy`, {
                                    params: {
                                        url: newAction.specUrl
                                    }
                                }).then(response => {
                                    setNewAction((value) => ({
                                        ...value,
                                        specString: response.data
                                    }))
                                }).catch(error => {
                                    console.error(error);
                                    setOpenSnackbar("Failed to fetch schema. Please try again.");
                                });
                            }
                        }}
                        className="text-nowrap ml-4 px-8"
                    >Import Schema</Button>
                    {importSchema && <Button variant="outlined"
                        color="primary"
                        className="text-nowrap ml-4"
                        onClick={() => setImportSchema(!importSchema)}
                    >Cancel</Button>}
                </Box>
                <OutlinedInput
                    id="schema"
                    multiline
                    rows={18}
                    fullWidth
                    inputComponent={TextareaAutosize}
                    spellCheck={false}
                    style={{
                        overflow: 'auto',
                        resize: 'both',
                        minHeight: '300px',
                    }}
                    className="font-mono"
                    value={newAction?.specString || ''}
                    onChange={async (event) => {
                        onSpecChange(event.target.value);
                    }} />
                {specError && <FormHelperText>{specError}</FormHelperText>}
                <Box className="flex justify-end my-4">
                    <TextField
                        variant="outlined"
                        label="Privacy Policy URL"
                        fullWidth
                        value={newAction?.privacyUrl || ''}
                        onChange={(event) => setNewAction((value) => ({
                            ...value,
                            privacyUrl: event.target.value
                        }))} />
                </Box>
            </Box>
            <Snackbar
                open={!!openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar("")}
            >
                <Alert onClose={() => setOpenSnackbar("")} severity="warning" className="w-full bg-white bg-opacity-75 text-black">
                    {openSnackbar}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AssistantConfigureAction;