import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from './environment';
import { Assistant } from './models';

function Assistants() {
  const [assistants, setAssistants] = useState<Assistant[]>([] as Assistant[]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAssistants = () => {
    setLoading(true);
    axios.get(`${SERVER_URL}/admin/assistants`)
      .then(response => setAssistants(response.data))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAssistants();

  }, []);

  const handleAdd = async () => {
    await axios.post<Assistant>(`${SERVER_URL}/admin/assistants`, {
      "model": "gpt-4o",
      "slack": true
    }).then((response) => navigate(`/assistants/${response.data.id}`))
      .catch(error => console.error(error));
  };

  const handleDelete = (assistantId: string) => {
    axios.delete(`${SERVER_URL}/admin/assistants/${assistantId}`)
      .then(() => fetchAssistants())
      .catch(error => console.error(error));
  };

  const handlePreview = (assistant: Assistant) => {
    navigate(`/preview/${assistant.id}`);
  };

  const handleUpdate = (assistant: Assistant) => {
    navigate(`/assistants/${assistant.id}`);
  }

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleOpenDialog = (id: string) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (!selectedId) return;
    handleDelete(selectedId);
    setOpenDialog(false);
  };

  return (
    <Box className="px-4">
      <Typography variant="h5" component="h2" gutterBottom>
        Assistants
      </Typography>
      {loading ? (
        <>
          {[...Array(3)].map((_, index) => (
            <Box key={index} className="my-4 w-full">
              <Skeleton variant="rectangular" width="100%" height={55} />
            </Box>
          ))}
        </>
      ) : assistants.length > 0 ? (
        <List component="nav" aria-label="main mailbox folders">
          {assistants.map((assistant, index) => (
            <Grid container alignItems="center" key={index}>
              <Grid item xs={11} sm={11}>
                <ListItem button onClick={() => handlePreview(assistant)}>
                  <Box className="pr-2">
                    {assistant.icon ?
                      <img src={assistant.icon} alt="Icon preview" style={{ width: '60px', height: '60px', borderRadius: '50%' }} /> :
                      <ThreePIcon style={{ fontSize: '60px' }} />}
                  </Box>
                  <Box className="flex flex-col flex-start">
                    <Typography variant="h6" className="pr-2">
                      {assistant.name || 'Unknown'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {assistant.description}
                    </Typography>
                  </Box>
                </ListItem>
              </Grid>
              <Grid item xs={1} sm={1}>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <IconButton color="primary" aria-label="preview" onClick={() => handleUpdate(assistant)}>
                    <CreateIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
                  <IconButton color="warning" aria-label="delete" onClick={() => handleOpenDialog(assistant.id)}>
                    <DeleteIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this assistant?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Grid>
            </Grid>
          ))}
        </List>
      ) : (
        <Typography variant="body1" component="p">
          The assistants list is empty.
        </Typography>
      )}
      <Box display="flex" justifyContent="flex-end" m={1}>
        <IconButton color="primary" aria-label="add" onClick={handleAdd}>
          <AddIcon className="text-6xl" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Assistants;