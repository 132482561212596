import { Card, CardContent, CardHeader } from "@mui/material";
import AssistantSlackMemories from "./AssistantSlackMemories";
import { Assistant } from "./models";

interface AssistantSlackOrganizationProps {
    assistant: Assistant;
}

const AssistantSlackOrganization = ({ assistant }: AssistantSlackOrganizationProps) => {

    return (
        <Card className="mt-4">
            <CardHeader title="Organization" />
            <CardContent>
                <AssistantSlackMemories assistant={assistant} orgId={assistant.orgId}/>
            </CardContent>
        </Card>
    );
}

export default AssistantSlackOrganization;