import ChatIcon from '@mui/icons-material/Chat';

const ChatBotIcon = ({ icon }: { icon: string | undefined }) => {
    return (
        <>
        { icon ? <img src = { icon } alt = "Chatbot icon" className = "rounded-full mr-2" style={{ width: '40px', height: '40px' }} />: <ChatIcon style={{ fontSize: 30 }} />}
        </>
    );
}

export default ChatBotIcon;