import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, Paper, TextField } from "@mui/material";
import axios from 'axios';
import { useState } from "react";
import { HEADER_HEIGHT } from './AssistantConfigure';
import ChatBubble from "./ChatBubble";
import { SERVER_URL } from './environment';
import icon from './images/icon.svg';
import { Assistant } from './models';

interface Message {
    id: number;
    text: string;
    sender: 'user' | 'bot';
}

interface AssistantCreateProps {
    assistant: Assistant;
    refreshAssistant: () => void;
}

const streamResponse = async (endpoint: string, timeout = 60 * 1000, chunkFn: (chunk: string) => void) => {
    const response = await fetch(endpoint, {
        headers: new Headers({
            'Authorization': axios.defaults.headers.common['Authorization']?.toString() || '',
        }),
        signal: AbortSignal.timeout(timeout),
    });
    if (!response.body) throw new Error('null response body')
    const reader = response.body.getReader();
    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            break;
        }
        chunkFn(new TextDecoder().decode(value))
    }
}

const wecomeMessageForNewVisitors = `Hello! I'll help you create your assistant. \n\n\n\n You can say something like "make a new assistant to help wecome new visitors to my website" or "create a new assistant that provides support for my products". \n\nWhat would you like to do?`;
const welcomeMessageForReturningVisitors = `Welcome back! It's great to see you again. \n\nI'm here to further assist you with creating or refining your assistant. You can continue enhancing your current projects, explore new features, or ask for specific support. \n\nDo you have something specific in mind today, or would you like to see some suggestions based on your previous activities?`;

const AssistantCreate: React.FC<AssistantCreateProps> = ({ assistant, refreshAssistant }) => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [messages, setMessages] = useState<Message[]>([{
        id: 0,
        text: assistant?.name || assistant?.description ? welcomeMessageForReturningVisitors : wecomeMessageForNewVisitors,
        sender: 'bot'
    }]);

    const handleSendMessage = async (message: string) => {
        if (message.trim()) {
            // Clear input field
            setMessage('');
            // Update conversation with user message
            setMessages(messages => [...messages, { id: messages.length, text: message, sender: 'user' }]);
            // Send message to backend and get response
            await sendMessageToBackend(message);
        }
    };

    const sendMessageToBackend = async (userMessage: string) => {
        setIsLoading(true);
        await streamResponse(
            `${SERVER_URL}/admin/assistants/${assistant?.id}/builder?message=${encodeURIComponent(userMessage)}`,
            60 * 1000,
            (chunk: string) => {
                setMessages(messages => {
                    // check if last message is from the bot
                    const lastMessage = messages[messages.length - 1]
                    if (lastMessage.sender === 'bot') {
                        // add chunk to end of the message
                        lastMessage.text += chunk;

                        return [...messages]
                    } else {
                        return [...messages, { id: messages.length + 1, text: chunk, sender: 'bot' }];
                    }
                })

            }
        ).catch((error) => {
            setError(error.response.data.message);
        }).finally(() => {
            refreshAssistant();
            setIsLoading(false)
        });
    };

    return (
        <>
            <Box className="mb-4" style={{ height: `calc(100vh - ${HEADER_HEIGHT}px - 6rem)`, overflow: 'auto' }}>
                <Paper className="h-full">
                    <Box style={{}} className="p-4 h-full">
                        {messages.map((message, index) => (
                            <div key={index} className={`text-${message.sender === 'bot' ? 'left' : 'right'}`}>
                                <ChatBubble text={message.text} sender={message.sender} icon={message.sender === 'bot' ? icon : undefined} />
                            </div>
                        ))}
                    </Box>
                </Paper>
            </Box>
            <Box>
                {error && <Box className="text-red-500 p-4">{error}</Box>}
            </Box>
            <Box className="flex flex-row justify-between p-4">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="builder-message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message..."
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(message)}
                    disabled={isLoading}
                />
                <Box className="ml-2">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSendMessage(message)}
                        disabled={isLoading}
                        style={{ height: '100%' }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AssistantCreate;