import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, CircularProgress, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { SERVER_URL } from "./environment";
import { Assistant } from "./models";

interface AssistantSlackMemoriesProps {
    assistant: Assistant;
    userId?: string;
    channelId?: string;
    orgId?: string;
    personaId?: string;
    setUserIsDisabledCallback?: (userId: string, disabled: boolean) => void;
}

const AssistantSlackUsers = ({ assistant, userId, channelId, orgId, personaId, setUserIsDisabledCallback }: AssistantSlackMemoriesProps) => {

    const [memories, setMemories] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [memoryValues, setMemoryValues] = useState({} as Record<string, string>);

    useEffect(() => {
        if (memories) {
            const initialMemoryValues = memories.reduce((acc, memory) => {
                acc[memory.id] = memory.value;
                return acc;
            }, {});
            setMemoryValues(initialMemoryValues);
        }
    }, [memories]);

    const handleMemoryChange = (id: string, value: string) => {
        setMemoryValues((prevValues: Record<string, string>) => ({
            ...prevValues,
            [id]: value
        }));
    };

    const getMemoriesForUser = useCallback(async () => {
        if (!userId) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users/${userId}`);
            const data = response.data;
            console.log(data);
            if (setUserIsDisabledCallback && userId) {
                if (!data?.user) {
                    setUserIsDisabledCallback(userId, true);
                } else {
                    setUserIsDisabledCallback(userId, data.user.disabled);
                }
            }
            if (data?.memories)
                setMemories(data.memories);
        } catch (err) {
            setError('Failed to get memories list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, userId, setUserIsDisabledCallback]);

    const getMemoriesForConversation = useCallback(async () => {
        if (!channelId) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/conversations/${channelId}`);
            const data = response.data;
            console.log(data);
            if (data?.memories)
                setMemories(data.memories);
        } catch (err) {
            setError('Failed to get memories list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, channelId]);

    const getMemoriesForOrganization = useCallback(async () => {
        if (!orgId) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/organizations/${orgId}`);
            const data = response.data;
            console.log(data);
            if (data?.memories)
                setMemories(data.memories);
        } catch (err) {
            setError('Failed to get memories list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, orgId]);

    const getMemoriesForPersona = useCallback(async () => {
        if (!personaId) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/personas/${personaId}`);
            const data = response.data;
            console.log(data);
            if (data?.memories)
                setMemories(data.memories);
        } catch (err) {
            setError('Failed to get memories list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, personaId]);

    const fetchMemories = useCallback(() => {
        if (userId) {
            getMemoriesForUser();
        } else if (channelId) {
            getMemoriesForConversation();
        } else if (orgId) {
            getMemoriesForOrganization();
        } else if (personaId) {
            getMemoriesForPersona();
        }
    }, [userId, channelId, orgId, personaId, getMemoriesForUser, getMemoriesForConversation, getMemoriesForOrganization, getMemoriesForPersona]);

    useEffect(() => {
        fetchMemories();
    }, [fetchMemories]);

    const editMemory = useCallback(async (memoryId: string, value: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.put(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users/${userId}/memories/${memoryId}`, { value });
            const data = response.data;
            console.log(data);
            fetchMemories();
        } catch (err) {
            setError('Failed to edit memory.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, userId, fetchMemories]);

    const deleteMemory = useCallback(async (memoryId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.delete(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/users/${userId}/memories/${memoryId}`);
            const data = response.data;
            console.log(data);
            fetchMemories();
        } catch (err) {
            setError('Failed to delete memory.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, userId, fetchMemories]);

    return (

        <Card variant="outlined">
            <CardHeader
                title="Memories"
            />
            <CardContent>
                {loading && <Box className="flex items-center justify-center min-h-screen">
                    <CircularProgress size={100} />
                </Box>}
                {error && <Typography color="error">{error}</Typography>}
                {memories?.map((memory, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}>
                            <Box className="my-3 text-ellipsis">
                                {memory.value}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                id={`memory-${index}`}
                                label="memory details"
                                variant="outlined"
                                value={memoryValues[memory.id]}
                                onChange={(e) => handleMemoryChange(memory.id, e.target.value)}
                                fullWidth
                            />                            </AccordionDetails>
                        <AccordionActions>
                            <Typography>
                                {memory.createdAt}
                            </Typography>
                            <Button size="small" color="primary" onClick={() => editMemory(memory.id, memoryValues[memory.id])}>
                                Edit
                            </Button>
                            <Button size="small" color="error" onClick={() => deleteMemory(memory.id)}>
                                Delete
                            </Button>
                        </AccordionActions>
                    </Accordion>
                ))}
                {memories.length === 0 && <Typography>No memories found.</Typography>}
            </CardContent>
        </Card>
    );
}

export default AssistantSlackUsers;