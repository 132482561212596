
import { ExpandMoreOutlined, ForumOutlined, GroupOutlined, LockOpenOutlined, LockPersonOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, Checkbox, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AssistantSlackMemories from './AssistantSlackMemories';
import { SERVER_URL } from './environment';
import { Assistant } from './models';

interface AssistantSlackConversationsProps {
    assistant: Assistant;
}

const AssistantSlackConversations = ({ assistant }: AssistantSlackConversationsProps) => {

    const [conversations, setConversations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getConversations = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/conversations`);
            const data = response.data;
            console.log(data);
            if (data?.channels)
                setConversations(data.channels);
        } catch (err) {
            setError('Failed to get conversations list.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id]);

    const channelsJoin = useCallback(async (channelId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/conversations/join`, { channelId });
            const data = response.data;
            console.log(data);
            getConversations();
        } catch (err) {
            setError('Failed to join slack channel.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, getConversations]);

    const channelsLeave = useCallback(async (channelId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${SERVER_URL}/admin/assistants/${assistant.id}/slack/conversations/leave`, { channelId });
            const data = response.data;
            console.log(data);
            getConversations();
        } catch (err) {
            setError('Failed to leave slack channel.');
        } finally {
            setLoading(false);
        }
    }, [assistant.id, getConversations]);

    useEffect(() => {
        getConversations();
    }, [getConversations]);

    const createHandleChange = (channelId: string) => {
        return async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (checked) {
                await channelsJoin(channelId);
            } else {
                await channelsLeave(channelId);
            }
        };
    };

    return (
        <Card variant="outlined" className="my-4">
            <CardHeader title="Conversations" />
            <CardContent>
                {loading && <Box className="flex items-center justify-center min-h-screen">
                    <CircularProgress size={100} />
                </Box>}
                {error && <Typography color="error">{error}</Typography>}
                {conversations?.map((conversation, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}>
                            <Checkbox checked={conversation.is_member || conversation.is_im} onChange={createHandleChange(conversation.id)} />
                            <Box className="m-2">
                                {conversation.is_mpim || conversation.is_group ? <GroupOutlined /> : 
                                    conversation.is_channel && <ForumOutlined />}
                            </Box>
                            <Box className="m-2">
                                {conversation.is_private ? <LockPersonOutlined /> : <LockOpenOutlined />} 
                            </Box>
                            <Typography className="m-2">
                                {conversation.is_channel && !conversation.is_mpim && conversation.name}
                                {conversation.is_channel && conversation.is_mpim && conversation.purpose.value}
                                {conversation.is_im && `DM with user ${conversation.user}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AssistantSlackMemories assistant={assistant} channelId={conversation.id} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </CardContent>
        </Card>
    );
}

export default AssistantSlackConversations;