import { Brightness4Rounded, Brightness7Rounded } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import TeamIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import BillingIcon from '@mui/icons-material/Payment';
import { Box, ButtonBase, Dialog, DialogContent, Drawer, List, ListItem, ListItemText, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import { Provider, User, createClient } from '@supabase/supabase-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import AssistantBuilder from './AssistantBuilder';
import AssistantPreviewRoute from './AssistantPreviewRoute';
import AssistantsGuard from './AssistantsGuard';
import BrandIcon from './components/BrandIcon';
import { ADMIN_SERVER_URL, SUPABASE_ANON_KEY, SUPABASE_URL } from './environment';
import SlackOauth from './SlackOauth';
import Teams from './Teams';

const supabase = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY
)

axios.defaults.withCredentials = true;

const views: { id: ViewType; title: string }[] = [
  { id: 'sign_in', title: 'Sign In' },
  // { id: 'sign_up', title: 'Sign Up' },
  { id: 'magic_link', title: 'Magic Link' },
  { id: 'forgotten_password', title: 'Forgotten Password' },
  { id: 'update_password', title: 'Update Password' },
  { id: 'verify_otp', title: 'Verify Otp' },
]

// const providers: Provider[] = ['google', 'github'];
const providers: Provider[] = [];

export interface AppProps {
  theme: Theme;
  toggleTheme: () => void;
}

const App: React.FC<AppProps> = ({ theme, toggleTheme }) => {
  const [user, setUser] = useState<User | null>(null);
  const [view, setView] = useState(views[0])
  const [showAuth, setShowAuth] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(false);


  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const setAccessToken = (token: string | undefined) => {
    if (!token)
      axios.defaults.headers.common['Authorization'] = '';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const logout = async () => {
    await supabase.auth.signOut();
    setUser(null);
  }

  useEffect(() => {
    const subscription = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION' && session) {
        // handle initial session
        setAccessToken(session.access_token);
        if (session.user)
          setUser(session.user);
      } else if (event === 'SIGNED_IN') {
        // handle sign in event
        setAccessToken(session?.access_token);
        if (session?.user)
          setUser(session.user);
        setShowAuth(false);
      } else if (event === 'SIGNED_OUT') {
        // handle sign out event
        setAccessToken(undefined);
        setUser(null);
        setShowAuth(true);
        setView({ id: 'sign_in', title: 'Sign In' });
      } else if (event === 'PASSWORD_RECOVERY') {
        setAccessToken(session?.access_token);
        if (session?.user)
          setUser(session?.user);
        setShowAuth(true);
        setView({ id: 'update_password', title: 'Update Password' });
      } else if (event === 'TOKEN_REFRESHED') {
        // handle token refreshed event
        setAccessToken(session?.access_token);
      } else if (event === 'USER_UPDATED') {
        // handle user updated event
        setUser(session?.user || null);
      }
    })

    return () => {
      subscription.data.subscription.unsubscribe();
    }
  }, []);



  const handleBillingRedirect = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${ADMIN_SERVER_URL}/billing`);

      // Assuming the response contains a URL to redirect to
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error redirecting to billing:', error);
    }
  };

  if (showAuth || !user) {
    return <Dialog open={true}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
    maxWidth="sm"
    fullWidth>
    <DialogContent>
      <Auth
      supabaseClient={supabase}
      view={view.id}
      appearance={{ theme: ThemeSupa }}
      providers={providers}
      theme="dark"
      showLinks={false}
    />
    </DialogContent>
  </Dialog>
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} className="bg-blue-700">
          <Box className="flex justify-between items-center p-4">
            <Box className="flex flex-row">
              <BrandIcon />
            </Box>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              classes={{ paper: 'w-64' }}
            >
              <List>
                <ListItem>
                  <EmailIcon className="pr-2" />
                  <Typography variant="body1" className="p-2" style={{ pointerEvents: 'none', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {user.email}
                  </Typography>
                </ListItem>
              </List>
              <List>
                <ButtonBase component={Link} to="/assistants" onClick={toggleDrawer(false)}>
                  <ListItem>
                    <HomeIcon className="pr-2" />
                    <ListItemText primary="Assistants" />
                  </ListItem>
                </ButtonBase>
              </List>
              <List>
                <ButtonBase onClick={handleBillingRedirect}>
                  <ListItem>
                    <BillingIcon className="pr-2" />
                    <ListItemText primary="Billing" />
                  </ListItem>
                </ButtonBase>
              </List>
              <List>
                <ButtonBase component={Link} to="/teams" onClick={toggleDrawer(false)}>
                  <ListItem>
                    <TeamIcon className="pr-2" />
                    <ListItemText primary="Teams" />
                  </ListItem>
                </ButtonBase>
              </List>
              <List>
                <ListItem component={ButtonBase} onClick={() => { toggleTheme(); toggleDrawer(false); }}>
                  <Box className="pr-2">{theme.palette.mode === 'dark' ? <Brightness7Rounded /> : <Brightness4Rounded />}</Box>
                  <ListItemText primary={theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'} />
                </ListItem>
              </List>
              <List>
                <ListItem component={ButtonBase} onClick={() => { logout(); toggleDrawer(false); }} className="text-red-500">
                  <LogoutIcon className="pr-2" />
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Drawer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Routes>
            <Route path="/slack/oauth" element={<SlackOauth />} />
            <Route path="/assistants" element={<AssistantsGuard />} />
            <Route path="/assistants/:assistant_id" element={<AssistantBuilder />} />
            <Route path="/assistants/:assistant_id/*" element={<AssistantBuilder />} />
            <Route path="/preview/:assistant_id" element={<AssistantPreviewRoute />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/signup" handle={() => setView({ id: 'sign_up', title: 'Sign Up' })} />
            <Route path="/" element={<Navigate to="/assistants" />} />
          </Routes>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;