import { Card, CardContent, CardHeader } from "@mui/material";
import AssistantSlackMemories from "./AssistantSlackMemories";
import { Assistant } from "./models";

interface AssistantSlackPersonaProps {
    assistant: Assistant;
}

const AssistantSlackPersona = ({ assistant }: AssistantSlackPersonaProps) => {

    return (
        <Card>
            <CardHeader title="Persona" />
            <CardContent>
                <AssistantSlackMemories assistant={assistant} personaId={assistant.id}/>
            </CardContent>
        </Card>
    );
}

export default AssistantSlackPersona;