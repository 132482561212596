import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';
import { Alert, Box, Button, IconButton, Paper, Snackbar, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import AssistantConfigure, { HEADER_HEIGHT } from "./AssistantConfigure";
import AssistantConfigureAction, { Action } from "./AssistantConfigureAction";
import AssistantCreate from "./AssistantCreate";
import AssistantPreview from "./AssistantPreview";
import AssistantSlack from './AssistantSlack';
import AssistantStyle from "./AssistantStyle";
import AssistantChats from './AssistantsChats';
import { SERVER_URL } from "./environment";
import { Assistant } from "./models";

const AssistantBuilder: React.FC<{}> = () => {
    const { assistant_id } = useParams();
    const [assistant, setAssistant] = useState<Assistant | null>(null);
    const [thread_id, setThreadId] = useState<string | null>(null);

    const [tab, setTab] = React.useState(0);
    const [subView, setSubView] = React.useState('configure');
    const [action, setAction] = React.useState<Action | null>(null);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const fetchAssistantInfo = useCallback(async () => {
        const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant_id}`);
        const data = response.data;
        setAssistant(data);
    }, [assistant_id]);

    useEffect(() => {
        fetchAssistantInfo();
    }, [assistant_id, fetchAssistantInfo]);

    const updateAssistant = async (newAssistant: Assistant) => {
        return await axios.put(`${SERVER_URL}/admin/assistants/${(newAssistant as Assistant).id}`, newAssistant)
            .then((response) => setAssistant(response.data))
            .catch(error => {
                console.error(error);
                setSnackBarSeverity("error");
                setOpenSnackbar(error.response?.data?.detail || error.message || "Update failed. Please try again.");
            });
    }

    const theme = useTheme();
    const isScreenLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const [openSnackbar, setOpenSnackbar] = useState("");
    const [snackBarSeverity, setSnackBarSeverity] = useState<"success" | "info" | "warning" | "error" | undefined>("warning");

    const handleShare = () => {
        navigator.clipboard.writeText(`https://components.reliablegenius.io/assistants/${assistant_id}`);
        setSnackBarSeverity("success");
        setOpenSnackbar("Copied to clipboard");
    };

    const handleOpenExternally = () => {
        window.open(`https://components.reliablegenius.io/assistants/${assistant_id}`, '_blank');
    };

    return (
        <Box className="flex flex-row w-full h-full">
            <Box className={`flex flex-col ${isScreenLarge ? 'w-1/2' : 'w-full'} h-full`}>
                <Box className="flex justify-between w-full">
                    <Button
                        component={RouterLink}
                        to="/assistants"
                        variant="contained"
                        className="m-4"
                        startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                    <Tabs value={tab}
                        onChange={handleTabChange}
                        aria-label="assistant builder tabs"
                        variant="fullWidth"
                        className="flex-grow mr-24">
                        <Tab label={<Typography variant="h6">Create</Typography>} />
                        <Tab label={<Typography variant="h6">Configure</Typography>} />
                        <Tab label={<Typography variant="h6">{assistant?.slack ? 'Slack' : 'Style'}</Typography>} />
                        <Tab label={<Typography variant="h6">Chats</Typography>} />
                        {!isScreenLarge && <Tab label={<Typography variant="h6">Preview</Typography>} />}
                    </Tabs>
                </Box>
                <Box className="flex flex-col p-4 w-full h-full">
                    {tab === 0 && assistant !== null && <AssistantCreate assistant={assistant} refreshAssistant={() => fetchAssistantInfo()} />}
                    {tab === 1 && assistant !== null && subView === 'configure' && <AssistantConfigure initialData={assistant} updateAssistant={updateAssistant} setSubView={setSubView} setAction={setAction} setOpenSnackbar={setOpenSnackbar} />}
                    {tab === 1 && assistant !== null && subView === 'action' && <AssistantConfigureAction action={action} updateAssistant={updateAssistant} setSubView={setSubView} />}
                    {(tab === 2 && assistant !== null) && (assistant.slack ? <AssistantSlack assistant={assistant} updateAssistant={updateAssistant} /> : <AssistantStyle assistant={assistant} updateAssistant={updateAssistant} />)}
                    {tab === 3 && assistant !== null && <AssistantChats assistant={assistant} setThreadId={setThreadId}/>}
                    {tab === 4 && <Paper style={{ height: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="p-4">
                        <AssistantPreview assistant={assistant} thread_id={thread_id}/>
                    </Paper>}
                </Box>
            </Box>
            {isScreenLarge && <Box className="flex flex-col justify-center w-1/2 h-full pr-4 pt-4 pb-4">
                <Box className="flex flex-row justify-conter">
                    <Typography variant="h6" component="h2" className="mx-auto pb-5 pl-16">
                        Preview
                    </Typography>
                    <Tooltip title="Share">
                        <IconButton onClick={handleShare}>
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Open Externally">
                        <IconButton onClick={handleOpenExternally}>
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Paper style={{ height: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="p-4">
                    <AssistantPreview assistant={assistant} thread_id={thread_id}/>
                </Paper>
            </Box>}
            <Snackbar
                open={!!openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar("")}
            >
                <Alert onClose={() => setOpenSnackbar("")} severity={snackBarSeverity} className="w-full bg-white bg-opacity-75 text-black">
                    {openSnackbar}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AssistantBuilder;