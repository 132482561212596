import { ContentCopy } from "@mui/icons-material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Button, Checkbox, FormControlLabel, Paper, Slider, Typography, useTheme } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { HEADER_HEIGHT } from "./AssistantConfigure";
import { Assistant } from "./models";

interface AssistantStyleProps {
    assistant: Assistant;
    updateAssistant: (assistant: Assistant) => Promise<void>;
}

const AssistantStyle: React.FC<AssistantStyleProps> = ({ assistant, updateAssistant }) => {

    const [newAssistant, setNewAssistant] = useState<Assistant>(assistant);

    const updateAssistantProperty = (property: keyof Assistant, value: any) => {
        setNewAssistant((oldAssistant) => ({
            ...oldAssistant,
            [property]: value
        }));
    }

    useDeepCompareEffect(() => {
        if (newAssistant !== assistant) {
            updateAssistant(newAssistant);
        }
    }, [newAssistant, assistant]);

    const theme = useTheme();

    return (
        <Box className="flex flex-col">
            <Paper style={{ maxHeight: `calc(100vh - ${HEADER_HEIGHT}px`, overflow: 'auto' }} className="p-4">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2} className="items-center">
                    <Box display="flex" flexDirection="row" justifyContent="center" className="items-center">
                        {assistant.icon && <img src={assistant.icon} alt="Chatbot icon" className="rounded-full" style={{ width: '80px', height: '80px' }} />}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="items-center mt-2">
                        <Typography variant="h4" align="center">{assistant.name}</Typography>
                        <Typography variant="body1" align="center">{assistant.description}</Typography>
                    </Box>
                </Box>
                <Box className="flex justify-center items-center">
                    <Box className={`${theme.palette.mode === 'dark' ? 'bg-gray-700' : 'bg-gray-300'} rounded py-1 px-2`}>{assistant.id}</Box>
                    <Tooltip title="Copy Assistant ID">
                        <IconButton onClick={() => copy(assistant.id)}>
                            <FileCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box className="flex flex-col my-2">
                    <Typography variant="subtitle1">Assistant Style Settings</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={assistant.isFullPage} onChange={() => updateAssistantProperty('isFullPage', !assistant?.isFullPage)} />}
                        label="Full Page Mode"
                    />
                    {!assistant.isFullPage && (
                        <FormControlLabel
                            control={<Checkbox checked={assistant.isOpen} onChange={() => updateAssistantProperty('isOpen', !assistant.isOpen)} />}
                            label="Open by Default"
                        />
                    )}
                    <FormControlLabel
                        control={<Checkbox checked={assistant?.theme === 'light'} onChange={() => updateAssistantProperty('theme', assistant.theme === 'light' ? 'dark' : 'light')} />}
                        label="Light Theme"
                    />
                    <Box className="py-6">
                        <Slider defaultValue={1} min={0.25} max={4} step={0.25} aria-label="Scale" valueLabelDisplay="auto" onChange={(event, value) => updateAssistantProperty('scale', value)} />
                        <Typography variant="subtitle2">Scale (default: 1) - Adjust the size of the chatbot</Typography>
                    </Box>
                </Box>
                <Typography variant="subtitle1">IFrame Embed Code</Typography>

                <Box className="flex justify-end my-2">
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ContentCopy />}
                        onClick={() => navigator.clipboard.writeText(
                            `<iframe src="https://components.reliablegenius.io/assistant/${assistant?.id}" width="100%" height="100%" frameborder="0"></iframe>`
                        )}
                    >
                        Copy Embed Code
                    </Button>
                </Box>

                <pre className="language-markup"><code >
                    &lt;iframe src="https://components.reliablegenius.io/assistant/{assistant?.id}" width="100%" height="100%" frameborder="0"&gt;&lt;/iframe&gt;
                </code></pre>

                <Typography variant="subtitle1">Script Embed Code</Typography>
                <Box className="flex justify-end my-2">
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ContentCopy />}
                        onClick={() => navigator.clipboard.writeText(
                            `<script src="https://components.reliablegenius.io/bundle.js"></scri>\n` +
                            `<reliable-genius-assistant assistant_id="${assistant?.id}"></reliable-genius-assistant>`
                        )}
                    >
                        Copy Embed Code
                    </Button>
                </Box>
                {/* Add more fields as needed */}
                <pre className="language-markup"><code >
                    &lt;script src="https://components.reliablegenius.io/bundle.js"&gt;&lt;/script&gt; <br />
                    &lt;reliable-genius-assistant assistant_id="{assistant?.id}"&gt;&lt;/reliable-genius-assistant&gt;
                </code></pre>
            </Paper>
        </Box>
    );
};

export default AssistantStyle;