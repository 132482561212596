import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import UserModal from './UserModal';
import { SERVER_URL } from './environment';

export interface User {
    id?: string;
    email?: string;
    role: Role;
    isBillingNotified?: boolean;
}

export enum Role {
    Owner = 'owner',
    Billing = 'billing',
    Developer = 'developer',
}

function Teams() {
    const [users, setUsers] = useState<Array<User>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        listTeams();
    }, []);

    const listTeams = () => {
        setLoading(true);
        axios.get(`${SERVER_URL}/admin/teams`)
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    };

    const deleteUser = (user: User) => {
        setLoading(true);
        axios.delete(`${SERVER_URL}/admin/teams/${user.id}`, { data: user })
            .then(() => {
                const updatedUsers = users.filter(u => u.id !== user.id);
                setUsers(updatedUsers);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    };

    const handleClose = () => {
        setOpen(false);
        listTeams();
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1">{error}</Typography>;
    }

    return (
        <Box className="px-4">
            <Typography variant="h5" component="h2" gutterBottom>
                Team
            </Typography>
            {users.map(user => (
                <Grid container key={user.id} className="my-2">
                    <Grid item xs={8} className="flex flex-col justify-center space-y-2">
                        <div className="flex items-center space-x-2">
                            <Typography variant="h6" className="font-bold">{user.email}</Typography>
                            <Typography variant="subtitle1" color="textSecondary">({user.role})</Typography>
                        </div>
                        <Typography variant="body2" color="textSecondary">
                            Billing Notificatons: <span className={user.isBillingNotified ? 'text-green-500' : 'text-red-500'}>{user.isBillingNotified ? 'Enabled' : 'Disabled'}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className="flex justify-end items-center">
                        <IconButton color="primary" aria-label="edit" onClick={() => {
                            setMode('edit');
                            setUser(user);
                            setOpen(true);
                        }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="warning" aria-label="delete" onClick={() => deleteUser(user)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            {users.length === 0 && <Typography variant="body1">No users found</Typography>}
            {/* Add user button to open add user modal */}
            <Box display="flex" justifyContent="flex-end" m={1}>
                <IconButton color="primary" aria-label="add" onClick={() => {
                    setMode('add');
                    setUser(undefined);
                    setOpen(true);
                }}>
                    <AddIcon className="text-6xl"/>
                </IconButton>
            </Box>
            {open && <UserModal mode={mode} user={user} handleClose={handleClose} />}
        </Box>
    );
}

export default Teams;