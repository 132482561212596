import { Box, Typography, useTheme } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import ChatBotIcon from './ChatBotIcon';

interface ChatBubbleProps {
    text: string;
    sender: string;
    icon: string | undefined;
}

const ChatBubble = ({ text, sender, icon }: ChatBubbleProps) => {
    const isBotMessage = sender === 'bot' || sender === 'assistant';
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box
            className={`flex ${isBotMessage ? 'justify-start' : 'justify-end'} mb-2 text-2xl`}
        >
            {isBotMessage && <ChatBotIcon icon={icon} />}
            <Box
                className={`p-2 rounded-lg ${isBotMessage ? (isDarkMode ? 'bg-gray-600' : 'bg-gray-50') : (isDarkMode ? 'bg-blue-900' : 'bg-blue-300')} ${isDarkMode ? 'text-white' : 'text-black'} max-w-9/10`}
            >
                {isBotMessage ?
                    <MuiMarkdown>{text}</MuiMarkdown> :
                    <Typography>{text}</Typography>
                }
            </Box>
        </Box>
    );
};

export default ChatBubble;