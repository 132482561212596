import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';
import { Alert, Box, Button, IconButton, Snackbar, SnackbarCloseReason, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import AssistantPreview from "./AssistantPreview";
import { SERVER_URL } from "./environment";
import { Assistant } from "./models";

const AssistantPreviewRoute = () => {
    const { assistant_id } = useParams();

    const [assistant, setAssistant] = useState<Assistant | null>(null);

    const fetchAssistantInfo = useCallback(async () => {
        const response = await axios.get(`${SERVER_URL}/admin/assistants/${assistant_id}`);
        const data = response.data;
        setAssistant(data);
    }, [assistant_id]);

    useEffect(() => {
        fetchAssistantInfo();
    }, [assistant_id, fetchAssistantInfo]);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleShare = () => {
        navigator.clipboard.writeText(`https://components.reliablegenius.io/assistants/${assistant_id}`);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleOpenExternally = () => {
        window.open(`https://components.reliablegenius.io/assistants/${assistant_id}`, '_blank');
    };

    return (
        <Box style={{ height: "calc(100vh - 180px" }}>
            <Box className="flex flex-row mx-4 items-center">
                <Button component={RouterLink}
                    to="/assistants"
                    variant="contained"
                    color="primary"
                    className="m-4"
                    startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <Typography variant="h5">Assistant Preview</Typography>
                <Tooltip title="Share">
                    <IconButton onClick={handleShare}>
                        <ShareIcon />
                    </IconButton>
                </Tooltip>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Copied to clipboard
                    </Alert>
                </Snackbar>
                <Tooltip title="Open Externally">
                    <IconButton onClick={handleOpenExternally}>
                        <OpenInNewIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <AssistantPreview assistant={assistant} />
        </Box>
    )
}

export default AssistantPreviewRoute;